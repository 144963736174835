import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BaseLinkStyles, UnorderedListStyles } from 'aether-marketing';

const AnswerContainer = styled.div`
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  position: relative;

  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  p {
    padding-right: 30px;
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
    margin-top: 16px !important;
  }

  li {
    margin-bottom: 16px;
  }

  &.collapse-answer {
    height: auto;
    opacity: 1;
    transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
`;

const AccordionCard = styled.div`
  box-shadow: none;
  border: 0;
  margin-bottom: 0;
`;

const Icon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #000000;
  z-index: 1;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #000000;
    transition: transform 0.3s ease-in-out;
  }

  &:before {
    top: 50%;
    left: 50%;
    width: 1px;
    height: 50%;
    margin-left: -0.5px;
    margin-top: -25%;
    transform: ${(props) => (props.toggled ? 'rotate(90deg)' : 'none')};
  }

  &:after {
    top: 50%;
    left: 50%;
    width: 50%;
    height: 1px;
    margin-left: -25%;
    margin-top: -1px;
    transform: ${(props) => (props.toggled ? 'rotate(180deg)' : 'none')};
  }

  &:hover {
    cursor: pointer;
  }
`;

const QuestionContainer = styled.div`
  padding: 24px 0;
  transition: background-color 0.3s ease-in-out;
  position: relative;
  z-index: 0;

  &.first {
    padding-top: 0;
    display: flex;
    justify-content: space-between;
  }

  &:focus {
    outline: none;
  }
`;

const Question = styled.h3`
  cursor: pointer;
`;

const Line = styled.hr`
  border-top: 1px solid #212121;
  margin-top: 0;
  margin-bottom: 0;
`;

function FAQ({ items, openedAccordion, onAccordionToggle }) {
  const colRenderer = (item, index) => (
    <AccordionCard key={`accordion-${index}`}>
      <QuestionContainer
        role="button"
        className={`${
          index === 0 ? 'first' : 'd-flex justify-content-between'
        }`}
        tabIndex={0}
        id={`accordion-${index}`}
        onKeyDown={() => onAccordionToggle(index)}
        onClick={() => {
          if (window.pmt('ampli')) {
            window.pmt('amplitudeEvent', [item.event]);
          }
          onAccordionToggle(index);
        }}
      >
        <Question className="strong mb-0 align-self-center">
          {item.question}
        </Question>
        <div className="ml-4 align-self-center">
          <Icon
            toggled={openedAccordion === `accordion-${index}` ? 1 : 0}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            aria-label="Open or close accordion"
            onClick={(e) => {
              e.stopPropagation(); // Prevent the click from bubbling up to the QuestionContainer
              if (window.pmt('ampli')) {
                window.pmt('amplitudeEvent', [item.event]);
              }
              onAccordionToggle(index);
            }}
          />
        </div>
      </QuestionContainer>
      <AnswerContainer
        className={
          openedAccordion === `accordion-${index}` ? 'collapse-answer' : ''
        }
        dangerouslySetInnerHTML={{ __html: item.answer }}
      />
      <div className="">
        <Line />
      </div>
    </AccordionCard>
  );

  return (
    <div className="row">
      <div className="col-12">
        <div>{items.map((item, index) => colRenderer(item, index))}</div>
      </div>
    </div>
  );
}

function FaqPlusMinusSection({ items, openedAccordion, onAccordionToggle }) {
  return (
    <FAQ
      items={items}
      openedAccordion={openedAccordion}
      onAccordionToggle={onAccordionToggle}
    />
  );
}

FAQ.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      event: PropTypes.string
    })
  ).isRequired,
  openedAccordion: PropTypes.string.isRequired,
  onAccordionToggle: PropTypes.func.isRequired
};

FaqPlusMinusSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      event: PropTypes.string
    })
  ).isRequired,
  openedAccordion: PropTypes.string.isRequired,
  onAccordionToggle: PropTypes.func.isRequired
};

export default FaqPlusMinusSection;
