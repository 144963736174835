/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
const pmUuid = require('@postman/uuid');

const Section = styled.section`
  background-color: ${({ backgroundColor }) => backgroundColor || '#ffffff'};
  padding: 60px 0;
  @media screen and (max-width: 576px) {
    padding: 30px 0;
  }
`;

const LeftTab = styled.div`
  @media screen and (max-width: 992px) {
    margin-bottom: 24px;
    width: 100%;
  }
`;

const RightTab = styled.div`
  align-content: space-between;
  border: 2px solid #c0a8e1;
  border-radius: 20px;
  opacity: 1;
  padding: 80px;
  position: relative;
  transition: opacity 0.5s linear;
  @media screen and (max-width: 992px) {
    margin-left: 0;
    padding: 0 24px;
    width: 100%;
  }
`;

const TabContainer = styled.ul`
  border-bottom: none;
  margin: 25px 0;
  svg.arrow-icon {
    height: 50%;
    position: absolute;
    right: 6%;
  }
  .nav-tabs {
    border-bottom: none;
    cursor: pointer;
  }
`;

const Tab = styled.li`
  align-items: start;
  display: block;
`;

const TabLink = styled.a`
  align-items: center;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  padding: 22px;
  position: relative;
  vertical-align: middle;
  width: 100%;
  pointer-events: auto;

  p {
    color: white;
  }

  &.active,
  .active,
  &.active p {
    background-color: #c0a8e1;
    color: #212121;
    cursor: pointer;
  }
  &.inactive,
  .inactive {
    background-color: transparent;
  }
`;

const TabIcon = styled.img`
  filter: brightness(0) invert(1);
  height: 30px;
  margin-right: 20px;
  &.active {
    filter: brightness(1) invert(0);
  }
`;

const TabGroup = styled.ul`
  background-color: ${(props) => props.theme.colors.grey_00};
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  position: sticky !important;
  top: 0px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 10;

  .show > .nav-link {
    background-color: ${(props) => props.theme.colors.grey_00} !important;
    color: ${(props) => props.theme.colors.grey_90};
    font-size: 16px;
    font-weight: 600;
    &:focus {
      outline: 0;
    }
    &:hover {
      color: ${(props) => props.theme.colors.grey_90};
      cursor: pointer;
    }
    .active {
      background-color: ${(props) => props.theme.colors.grey_00} !important;
    }
  }
`;

const DropdownMenu = styled.li`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  a {
    white-space: normal;
  }
  a,
  a.active {
    color: #212121;
  }

  @media screen and (max-width: 992px) {
    a {
      border-radius: 10px !important;
    }
  }

  a.dropdown-item {
    font-size: 14px;
    padding: 8px 16px;
  }

  .dropdown-item.active,
  .dropdown-item:active,
  .dropdown-item:focus,
  .nav-link.active {
    background-color: ${(props) => props.theme.colors.grey_00};
    color: ${(props) => props.theme.colors.grey_90};
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }

  .dropdown-item:hover {
    background-color: #f8f9fa;
  }

  .nav-link {
    padding: 12px 16px;
    > svg {
      display: inline-block;
      margin-left: 6px;
      width: 10px;
      transition: 0.1s ease-in-out;
      transform: rotate(0deg);
      &.show {
        transform: rotate(180deg);
      }

      @media screen and (max-width: 992px) {
        float: right;
        position: relative;
        top: 6px;
      }

      &:active,
      &:focus {
        outline: none;
      }
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
`;

const TabLinksMobile = styled.div`
  background-color: ${(props) => props.theme.colors.grey_00};
  border: 1px solid #c0a8e1;
  border-radius: 0;
  margin-top: 4px;
  padding: 12px;
  text-align: left;

  a {
    padding: 0;
    white-space: normal;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const TabPaneWeb = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0em;
  width: 100%;

  ${({ isActive }) => css`
    animation: 0.03s ${isActive ? fadeIn : fadeOut}
      cubic-bezier(0.075, 0.82, 0.165, 1);
    opacity: ${isActive ? 1 : 0};
  `}

  img {
    margin: 0 auto;
    width: 80%;
  }
`;

const TabPaneMobile = styled.div`
  @media screen and (max-width: 992px) {
    display: block;
    &.tab-pane {
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }
    &.tab-pane.active {
      opacity: 1;
    }
  }

  @media screen and (min-width: 992px) {
    display: none !important;
  }
`;

export const TabHeader = React.memo(({ items, activeTab, setActiveTab }) => {
  return (
    <LeftTab className="col-12 col-lg-4 pr-md-0">
      <TabContainer
        className="nav nav-tabs d-none d-lg-block"
        id="PricingTabs"
        role="tablist"
      >
        {items.map((item) => (
          <Tab key={pmUuid()} role="presentation">
            <TabLink
              data-toggle="tab"
              tabIndex="0"
              id={`${item.left.id}-tab`}
              data-target={`#${item.left.id}`}
              role="tab"
              aria-controls={item.left.id}
              aria-selected={item.left.id === activeTab ? 'true' : 'false'}
              key={item.left.id}
              onClick={() => setActiveTab(item.left.id)}
              className={item.left.id === activeTab ? 'active' : ''}
              isActive={item.left.id === activeTab}
            >
              <TabIcon
                className={item.left.id === activeTab ? 'active' : ''}
                src={item.left.icon.src}
                alt={item.left.icon.alt}
              />
              <p className="font-weight-bold mb-0">{item.left.title}</p>
              <svg
                className={`arrow-icon ${
                  item.left.id === activeTab ? 'd-block' : 'd-none'
                }`}
                width="11"
                height="19"
                viewBox="0 0 11 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.295784 18.0894C-0.0341415 17.764 -0.0341414 17.2363 0.295784 16.9109L8.14668 9.16683L0.295786 1.42275C-0.0341393 1.09732 -0.0341392 0.569679 0.295786 0.244244C0.625712 -0.0811958 1.16063 -0.0811957 1.49055 0.244244L9.93883 8.57758C10.2688 8.90301 10.2688 9.43065 9.93883 9.75609L1.49055 18.0894C1.16062 18.4149 0.62571 18.4149 0.295784 18.0894Z"
                  fill="#212121"
                />
              </svg>
            </TabLink>
          </Tab>
        ))}
      </TabContainer>
      <div className="col-12 d-lg-none d-block position-relative p-0">
        <TabGroup
          className="nav nav-pills sticky-top"
          id="mobilePricingTabs"
          role="tablist"
        >
          <DropdownMenu className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="##"
              role="button"
              aria-expanded="false"
            >
              {items.find((item) => item.left.id === activeTab).left.title}
              <svg
                className="arrow-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="#6b6b6b"
              >
                <g>
                  <path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" />
                </g>
              </svg>
            </a>
            <TabLinksMobile className="dropdown-menu w-100">
              {items.map((item) => (
                <a
                  className="dropdown-item border-none"
                  href={`#${item.left.id}-pricing-tab`}
                  key={pmUuid()}
                  onClick={() => {
                    setActiveTab(item.left.id);
                  }}
                  id={`${item.left.id}-tab`}
                  data-toggle="tab"
                  role="tab"
                  aria-controls={`${item.left.id}-tab`}
                  aria-selected={item.left.id === activeTab}
                >
                  {item.left.title}
                </a>
              ))}
            </TabLinksMobile>
          </DropdownMenu>
        </TabGroup>
      </div>
    </LeftTab>
  );
});

TabHeader.displayName = 'TabHeader';

const TabContent = React.memo(({ items, activeTab }) => (
  <RightTab className="col-12 col-lg-8">
    <div className="tab-content">
      {items.map((item) => (
        <React.Fragment key={item.left.id}>
          <div className="d-lg-block d-none">
            <TabPaneWeb
              isActive={activeTab === item.left.id}
              id={item.left.id}
              role="tabpanel"
              aria-labelledby={`${item.left.id}-tab`}
              className={`${
                item.left.id === activeTab ? 'tab-pane' : 'opacity-0'
              }`}
            >
              <img src={item.right.img.src} alt={item.right.img.alt} />
            </TabPaneWeb>
          </div>
          <TabPaneMobile
            isActive={activeTab === item.left.id}
            id={item.left.id}
            role="tabpanel"
            aria-labelledby={`${item.left.id}-tab`}
            className={`tab-pane ${item.left.id === activeTab ? 'active' : ''}`}
          >
            <img src={item.right.img.src} alt={item.right.img.alt} />
          </TabPaneMobile>
        </React.Fragment>
      ))}
    </div>
  </RightTab>
));

TabContent.displayName = 'TabContent';

function Tabs({ title, description, backgroundColor, items }) {
  const [activeTab, setActiveTab] = useState(items[0].left.id);

  const memoizedItems = useMemo(() => items, [items]);
  const memoizedActiveTab = useMemo(() => activeTab, [activeTab]);

  return (
    <Section backgroundColor={backgroundColor}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 mb-5">
            {title && <h2 className="text-white">{title}</h2>}
            {description && (
              <p className="subtitle text-white">{description}</p>
            )}
          </div>
        </div>
        <div className="row">
          <TabHeader
            items={memoizedItems}
            activeTab={memoizedActiveTab}
            setActiveTab={setActiveTab}
          />
          <TabContent items={memoizedItems} activeTab={memoizedActiveTab} />
        </div>
      </div>
    </Section>
  );
}

function TabsSection({ items, title, description, backgroundColor }) {
  return (
    <Tabs
      items={items}
      title={title}
      description={description}
      backgroundColor={backgroundColor}
    />
  );
}

export default TabsSection;
