import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SvgContainer = styled.div`
  svg {
    fill: ${(props) => props.fillColor || 'currentColor'};
    transition: fill 0.3s ease;
  }
`;

function SVGComponent({ svgContent, fillColor, hoverFillColor }) {
  const svgRef = useRef(null);
  const originalFillColor = useRef(null);

  useEffect(() => {
    const svgElement = svgRef.current;
    if (svgElement) {
      const paths = svgElement.querySelectorAll('path');
      const firstPath = paths[0];

      // Saves the original path fill color for hover effect
      if (firstPath) {
        originalFillColor.current =
          firstPath.getAttribute('fill') || fillColor || 'currentColor';
      }

      const handleMouseEnter = () => {
        if (firstPath) {
          firstPath.style.fill = hoverFillColor || originalFillColor.current;
        }
      };

      const handleMouseLeave = () => {
        if (firstPath) {
          firstPath.style.fill = originalFillColor.current;
        }
      };

      paths.forEach((path) => {
        path.addEventListener('mouseenter', handleMouseEnter);
        path.addEventListener('mouseleave', handleMouseLeave);
      });

      return () => {
        paths.forEach((path) => {
          path.removeEventListener('mouseenter', handleMouseEnter);
          path.removeEventListener('mouseleave', handleMouseLeave);
        });
      };
    }
    return () => {};
  }, [fillColor, hoverFillColor]);

  return (
    <SvgContainer fillColor={fillColor} hoverFillColor={hoverFillColor}>
      <div ref={svgRef} dangerouslySetInnerHTML={{ __html: svgContent }} />
    </SvgContainer>
  );
}
SVGComponent.propTypes = {
  svgContent: PropTypes.string.isRequired,
  fillColor: PropTypes.string,
  hoverFillColor: PropTypes.string
};

SVGComponent.defaultProps = {
  fillColor: 'currentColor',
  hoverFillColor: null
};

export default SVGComponent;
