import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
/* eslint-enable */

import axios from 'axios';
import FormContainer from './FormContainer';
import MarketoForm from './MarketoForm';
import FormLegalAdditionalText from '../components/FormLegalAdditionalText';
import FormButton from '../components/FormButton';
import handleErrorValidation from '../helpers/handleErrorValidation';
import {
  FormTitle,
  FormSubtitle,
  FormFailText
} from '../helpers/handleFormCopy';
import FormFields from '../helpers/handleFormFields';
import {
  selectFormById,
  setInitialValues,
  updateInitialTypes
} from '../helpers/handleFormLoad';
import handleFormSubmission from '../helpers/handleFormSubmission';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const FormStyles = styled.div`
  input {
    border: 1px solid #ff6c37;
    border-radius: 4px !important;
    height: 42px;
    background: transparent !important;
    border-radius: 10px 0 0 10px;
    color: white;
  }

  button {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.orange_50};
    font-weight: 600;
    padding: 8px 16px;
    transition: background-color 50ms ease-in-out, border-color 50ms ease-in-out;
    border-radius: 0 4px 4px 0;
    height: 42px;
    border: 1px solid ${(props) => props.theme.colors.orange_50};

    :focus {
      border-radius: 0px 4px 4px 0;
      outline: none;
    }
    :hover,
    :active {
      opacity: 1;
      background-color: ${(props) => props.theme.colors.orange_60};
    }
  }
  value {
    color: white;
  }
  .form-control {
    color: white;
    @media (max-width: 325px) {
      /* width: 154px; */
    }
  }
  .form-control:focus {
    border: 1px solid #ffb203;
    box-shadow: 0 0 0 1px #ffb203;
    border-radius: 4px;
  }
  .FormLegalAdditionalText {
    color: white !important;
    font-size: 14px !important;
    margin-top: -15px;
  }
  @media (min-width: 992px) {
    margin-left: -20px;
  }
  @media (max-width: 992px) {
    .single {
      padding: 0;
      width: auto !important;
      margin-left: 0 !important;
    }
  }
  /* @media (max-width: 576px) {
    form {
      position: relative;
    }

  } */
`;

const ButtonStyled = styled.div`
  margin-top: -62px;
  margin-left: -15px;
  @media (max-width: 576px) {
    display: flex;
    align-items: start;
    margin-left: 59%;
    margin-top: -96px;
    button {
      font-size: 12px !important;
    }
  }
`;

function SingleInputForm(data) {
  const { form } = data;
  const {
    title,
    subtitle,
    marketoForm,
    submitButton,
    addOnLegalText,
    addOnCheckboxLabels
  } = form;
  const { id } = marketoForm;

  const [useFormData, setFormData] = useState([]);
  const [throwFailMessage, setFailMessage] = useState();

  const initialValues = {};
  const fields = useFormData;
  setInitialValues(initialValues, fields);

  useEffect(() => {
    /* 1. get form data with /mkapi/forms.json endpoint
     * 2. if response fails or id is not found, use cached version from /bff-data/forms.json as fallback
     * 3. if cached response fails or id is not found, render message to user to avoid errors
     */

    const fetchForms = async () => {
      try {
        /* eslint-disable global-require, import/no-dynamic-require */
        const response = require(`../../../../../bff-data/forms.json`);
        const selectedForm = selectFormById(response, id);
        const hasFormData = selectedForm.length !== 0;

        if (hasFormData) {
          setFormData(selectedForm);
          setFailMessage(false);
        } else {
          throw new Error();
        }
      } catch (error) {
        try {
          /* eslint-disable global-require, import/no-dynamic-require */
          /* eslint-enable */
          const { data: fallbackResponse } = await axios.get(
            `/mkapi/forms.json?t=${new Date().getTime()}`
          );
          const selectedForm = selectFormById(fallbackResponse, id);
          const hasFormData = selectedForm.length !== 0;
          setFailMessage(!hasFormData);
          setFormData(selectedForm);
        } catch (err) {
          setFailMessage(true);
        }
      }
    };

    fetchForms();
  }, [id]);

  return (
    <FormStyles>
      {fields.length > 0 ? (
        <FormContainer
          data-test="isFormLoading"
          className="bg-transparent text-white single ml-md-5 ml-lg-0"
        >
          <FormTitle title={title} />
          <FormSubtitle data={subtitle} />
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmission(id, data, values, setSubmitting);
            }}
            validationSchema={handleErrorValidation(fields)}
          >
            <Form
              id={`form-${id}`}
              noValidate
              className="row align-content-center"
            >
              {fields.map((field) => {
                // properties coming from marketo
                const {
                  Id,
                  Datatype,
                  DefaultValue,
                  IsRequired,
                  InputLabel,
                  Maxlength,
                  Name,
                  PicklistValues,
                  PlaceholderText,
                  VisibleRows,
                  VisibilityRule
                } = field;
                const types = updateInitialTypes(Datatype);
                return (
                  // set attributes and custom props
                  <div className="col-lg-9 col-8" key={pmUuid()}>
                    <FormFields
                      isEmail
                      key={pmUuid()}
                      placeholder={PlaceholderText || 'yourname@company.com'}
                      inputlabel={InputLabel || ''}
                      label={PlaceholderText || Name}
                      name={Name || ''}
                      mkId={Id || ''}
                      required={IsRequired || false}
                      max={Maxlength || ''}
                      type={types}
                      // Dropdowns and Checkboxes
                      dropdownDefaultValue={DefaultValue || ''}
                      selectValues={PicklistValues || ''}
                      VisibilityRule={VisibilityRule || ''}
                      // Text Area
                      rows={VisibleRows || ''}
                      // Legal Text hide for Post/Con
                      // htmlText={Htmltext || ''}
                      // Custom Props
                      addOnCheckboxLabels={
                        addOnCheckboxLabels &&
                        field.Datatype === 'single_checkbox'
                          ? addOnCheckboxLabels
                          : null
                      }
                    />
                  </div>
                );
              })}
              <ButtonStyled className="">
                <FormButton submitButton={submitButton} />
              </ButtonStyled>
              {addOnLegalText && (
                <FormLegalAdditionalText addOnLegalText={addOnLegalText} />
              )}
            </Form>
          </Formik>
          <MarketoForm data={marketoForm} />
        </FormContainer>
      ) : null}

      {throwFailMessage ? (
        <FormContainer>
          <FormFailText />
        </FormContainer>
      ) : null}
    </FormStyles>
  );
}

export default SingleInputForm;
