import React, { Component } from 'react';
/* eslint-enable */

import PropTypes from 'prop-types';
import { Divider, SectionStyles, BaseButton } from 'aether-marketing';
import CardBasic from './CardBasic';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

class FilterByExpirationDate extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      data,
      pastEvents: [],
      upcomingEvents: []
    };
  }

  componentDidMount() {
    this.getEvents();
  }

  getEvents() {
    const { data, pastEvents, upcomingEvents } = this.state;
    const { items } = data;

    items.forEach((event) => {
      if (new Date(event.expDate) < new Date()) {
        pastEvents.push(event);
        /* eslint-disable no-param-reassign */
        event.link.text = 'Watch Now';
      } else {
        upcomingEvents.push(event);
        event.link.text = 'Learn more';
        /* eslint-enable  */
      }
    });
    this.setState({ pastEvents });
    this.setState({ upcomingEvents });
  }

  render() {
    const { data, pastEvents, upcomingEvents } = this.state;
    const {
      header,
      secondHeader,
      fullWidthImage,
      secondBackgroundColor,
      subscribeForm
    } = data;
    return (
      <>
        <SectionStyles className={upcomingEvents === undefined ? 'd-none' : ''}>
          <div className="container">
            {upcomingEvents.length >= 1 && header && header.headerText && (
              <div className="row">
                <div className="col-12 text-center">
                  <h2 className="mb-3">{header.headerText}</h2>
                </div>
              </div>
            )}
            <div className="row justify-content-center">
              {upcomingEvents.reverse().map((item) => {
                return (
                  <CardBasic
                    key={pmUuid()}
                    className="mb-5"
                    col={item.colWidth || ''}
                    textPadding={item.textPadding}
                    textAlign={item.textAlign}
                    eyebrow={item.eyebrow}
                    tags={item.tags}
                    banner={item.banner}
                    media={item.media}
                    header={item.header}
                    body={item.body}
                    link={item.link}
                    hover={item.hover}
                    clickableCardLink={item.clickableCardLink}
                    src={item.link.src}
                    target={item.link.target}
                    ratio={item.ratio}
                    fullWidthImage={fullWidthImage}
                    roundedCorners={item.roundedCorners && item.roundedCorners}
                  />
                );
              })}
            </div>
            {/* This is a temporary section, placeholder while team is getting together Season 2 */}
            {subscribeForm && (
              <div className="col-12 text-center">
                <BaseButton
                  buttonType="secondary"
                  type="button"
                  data-toggle="modal"
                  data-target={`#modal-${subscribeForm.button.id}`}
                  isSecondary={subscribeForm.button === 'secondary'}
                  className="mb-0 mr-0"
                >
                  {subscribeForm.button.text}
                </BaseButton>
              </div>
            )}
          </div>
        </SectionStyles>
        <SectionStyles
          backgroundColor={secondBackgroundColor}
          className={pastEvents.length > 0 ? 'd-block' : 'd-none'}
        >
          <div className="container">
            {secondHeader && secondHeader.headerText && (
              <div className="row">
                <div className="col-12 text-center">
                  <h2>{secondHeader.headerText}</h2>
                </div>
              </div>
            )}
            <div className="row justify-content-center">
              {pastEvents.reverse().map((pastItem) => {
                return (
                  <CardBasic
                    key={pmUuid()}
                    col={pastItem.colWidth || ''}
                    textPadding={pastItem.textPadding}
                    textAlign={pastItem.textAlign}
                    eyebrow={pastItem.eyebrow}
                    tags={pastItem.tags}
                    media={pastItem.media}
                    header={pastItem.header}
                    body={pastItem.body}
                    link={pastItem.link}
                    hover={pastItem.hover}
                    clickableCardLink={pastItem.clickableCardLink}
                    src={pastItem.link.src}
                    target={pastItem.link.target}
                    ratio={pastItem.ratio}
                    fullWidthImage={fullWidthImage}
                    className="mb-5"
                    roundedCorners={
                      pastItem.roundedCorners && pastItem.roundedCorners
                    }
                  />
                );
              })}
            </div>
          </div>
        </SectionStyles>
        {(data.divider === true && <Divider />) || null}
      </>
    );
  }
}

FilterByExpirationDate.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.shape({
      headerText: PropTypes.string,
      tag: PropTypes.string
    }).isRequired,
    secondHeader: PropTypes.objectOf(PropTypes.string).isRequired,
    fullWidthImage: PropTypes.bool,
    secondBackgroundColor: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

const cardGridFilterByDate = (data) => <FilterByExpirationDate data={data} />;

export default cardGridFilterByDate;
