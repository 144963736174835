import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  SectionStyles,
  BaseLinkStyles,
  UnorderedListStyles,
  OrderedListStyles,
  Divider
} from 'aether-marketing';
import { BlockQuoteStyles } from '../case-studies/quoteLarge';
import AspectRatioImagePlugin from '../v5_images/AspectRatioImagePlugin';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const runtime = typeof document === 'object';

const SliderContainer = styled(SectionStyles)`
  height: ${(props) => !props.toggleIcons && '350px'};

  @media screen and (max-width: 768px) {
    margin-bottom: ${(props) => (props.toggleIcons ? '30px' : '0')};
    padding: 60px 0 !important;
  }
  @media screen and (max-width: 576px) {
    height: ${(props) => !props.toggleIcons && '450px'};
    padding: 60px 0 !important;
  }
`;
const ContentContainer = styled.div`
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }

  ol {
    ${OrderedListStyles.componentStyle.rules}
  }

  blockquote {
    ${BlockQuoteStyles.componentStyle.rules}
    font-size: 22px;
    margin-top: ${(props) => (props.toggleIcons ? '30px' : '0')};
    margin-bottom: 0;
    @media screen and (max-width: 576px) {
      font-size: 18px;
    }
    p {
      margin-bottom: 0;
    }
  }
`;

const CarouselIndicators = styled.ol`
  width: 100%;
  margin: 0;
  border: none;
  li {
    text-indent: 0px;
    background: transparent;
    margin: 0 24px;
    width: 120px;
    border: none;
    filter: grayscale(100%);
    &.active {
      filter: grayscale(0%);
    }
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    bottom: -30px;
  }
`;

const CarouselBody = styled.div`
  height: 100%;
  @media screen and (min-width: 768px) {
    height: ${(props) =>
      props.toggleIcons === true && props.items.length > 1 ? '310px' : '100%'};
  }
`;

const CarouselIcon = styled.span`
  &.carousel-control-prev-icon,
  &.carousel-control-next-icon {
    background-image: none;
  }
`;

const ContentIcons = styled.img`
  height: ${(props) => props.height || '32px'};
  position: relative;
`;

function SliderV6(props) {
  const { items, backgroundColor, padding, toggleIcons, divider, interval } =
    props;

  const setBackgroundColor = backgroundColor ? `${backgroundColor}` : '';
  const setPadding = padding ? `${padding}` : '';
  return (
    <>
      <SliderContainer
        toggleIcons={toggleIcons}
        style={{
          backgroundColor: `${setBackgroundColor}`,
          padding: `${setPadding}`
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                id="company"
                className="carousel slide"
                data-pause={false}
                data-ride="carousel"
                data-interval={interval}
              >
                <CarouselBody
                  className="carousel-inner"
                  toggleIcons={toggleIcons}
                  items={items}
                >
                  {items.map((item, index) => {
                    const bodyId = `CarouselBody${index}`;
                    const bodyHTML = item.body.join('');
                    if (runtime) {
                      setTimeout(() => {
                        document.getElementById(bodyId).innerHTML = bodyHTML;
                      }, 1000);
                    }
                    return (
                      <div
                        key={pmUuid()}
                        className={
                          item.index.toString() === '0'
                            ? 'carousel-item active'
                            : 'carousel-item'
                        }
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-12 col-lg-8 offset-lg-2">
                              {toggleIcons && (
                                <div className="d-flex justify-content-center">
                                  <ContentIcons
                                    height={item.media.height}
                                    src={item.media.src}
                                    alt={item.media.alt}
                                  />
                                </div>
                              )}
                              <ContentContainer toggleIcons={toggleIcons}>
                                <div id={bodyId} />
                              </ContentContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </CarouselBody>
                <CarouselIndicators className="carousel-indicators">
                  {items.length > 1 &&
                    items.map((item) => {
                      return (
                        <li
                          key={pmUuid()}
                          data-target="#company"
                          data-slide-to={item.index}
                          className={
                            item.index.toString() === '0' ? 'active' : ''
                          }
                        >
                          {toggleIcons && items.length > 1 && (
                            <AspectRatioImagePlugin
                              src={item.media.src}
                              role="img"
                              ratio={
                                item.media.ratio ? item.media.ratio : '1/1'
                              }
                              aria-label={item.media.alt || ''}
                              height={
                                item.media.height ? item.media.height : '100%'
                              }
                              marginBottom={
                                item.media.marginBottom
                                  ? item.media.marginBottom
                                  : 0
                              }
                            />
                          )}
                        </li>
                      );
                    })}
                </CarouselIndicators>
                {items.length > 1 ? (
                  <>
                    <a
                      className="carousel-control-prev"
                      href="#company"
                      role="button"
                      data-slide="prev"
                    >
                      <CarouselIcon
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href="#company"
                      role="button"
                      data-slide="next"
                    >
                      <CarouselIcon
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Next</span>
                    </a>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </SliderContainer>
      {(divider === true && <Divider />) || null}
    </>
  );
}

SliderV6.propTypes = {
  backgroundColor: PropTypes.string,
  padding: PropTypes.string,
  toggleIcons: PropTypes.bool,
  divider: PropTypes.bool,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.string,
        media: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
          ratio: PropTypes.string
        }),
        body: PropTypes.arrayOf([null])
      })
    )
  ]),
  interval: PropTypes.string
};

SliderV6.defaultProps = {
  items: null,
  backgroundColor: '#FFFFFF',
  padding: null,
  toggleIcons: true,
  divider: false,
  interval: '5000'
};

export default SliderV6;
