/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';
import { Divider, SectionStyles } from 'aether-marketing';

import FaqsPlusMinusSection from './faqPlusMinusSection';

const EmbedContainer = styled.div`
  position: relative;
  width: 0%;
  padding: 0;
  overflow: hidden;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s ease-in-out;
  border-radius: 10px;
  border: 1px solid #e6e6e6;

  &.visible {
    opacity: 1;
    width: 100%;
    visibility: visible;
  }

  &.fullwrap {
    border-radius: ${(props) => props.theme.borderRadius.large};
    border: 4px solid #e6e6e6;
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
  }

  &.top-bottom {
    border-top: 4px solid #e6e6e6;
    border-bottom: 4px solid #e6e6e6;
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    max-width: 1600px;
    margin: auto;

    @media screen and (min-width: 1600px) {
      border: 4px solid #e6e6e6;
      border-radius: ${(props) => props.theme.borderRadius.large};
    }
  }
`;

function SideBySideTabsSection({
  items,
  backgroundColor,
  className,
  divider,
  header,
  paddingTop,
  paddingBottom,
  fullWidth,
  reverseOrder
}) {
  const [openedAccordion, setOpenedAccordion] = useState('accordion-0');

  const handleAccordionToggle = (index) => {
    const newAccordion = `accordion-${index}`;
    setOpenedAccordion((prev) => (prev === newAccordion ? null : newAccordion));
  };

  const textSection = (
    <div
      className={`col-sm-12 col-md-10 offset-md-1 col-lg-4 mt-lg-0 mt-5 ${
        reverseOrder ? 'order-lg-1 offset-lg-1' : 'order-lg-0 offset-lg-0 '
      } `}
    >
      <FaqsPlusMinusSection
        items={items}
        openedAccordion={openedAccordion}
        onAccordionToggle={handleAccordionToggle}
      />
    </div>
  );

  const mediaSection = (
    <div
      className={`col-12 mb-lg-0 col-lg-7 ${
        reverseOrder ? 'order-lg-0 offset-lg-0' : 'order-lg-1 offset-lg-1'
      } order-0 `}
    >
      {items.map((item, index) => (
        <EmbedContainer
          key={item.video.videoEmbed}
          className={`embed-responsive embed-responsive-16by9 ${className} ${
            openedAccordion === `accordion-${index}` ||
            (!openedAccordion && index === 0)
              ? 'visible'
              : ''
          }`}
        >
          <div>
            <video
              preload="metadata"
              webkit-playsinline
              playsInline
              src={item.video.videoEmbed}
              type={item.video.videoType}
              poster={item.video.poster}
              autoPlay={item.video.autoPlay}
              muted={item.video.muted}
              loop={item.video.loop}
              controls={item.video.controls}
            >
              <track
                label="English"
                kind="captions"
                srcLang="en"
                src={item.video.vttFile}
                default
              />
              Sorry, your browser doesn&apos;t support embedded videos.
            </video>
          </div>
        </EmbedContainer>
      ))}
    </div>
  );

  return (
    <>
      <SectionStyles
        className="mb-5"
        backgroundColor={backgroundColor}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
      >
        <div className="container">
          {header && header.title && (
            <div className="row mb-5">
              <div className={`col-12 ${header.className}`}>
                {header.title && (
                  <h2 className="mb-4 text-left">{header.title}</h2>
                )}
                {header.body && (
                  <div
                    dangerouslySetInnerHTML={{ __html: header.body.join('') }}
                  />
                )}
              </div>
            </div>
          )}

          <div className="row">
            {reverseOrder ? (
              <>
                {mediaSection}
                {textSection}
              </>
            ) : (
              <>
                {textSection}
                {mediaSection}
              </>
            )}
          </div>
        </div>
      </SectionStyles>

      {(divider === true && <Divider fullWidth={fullWidth} />) || null}
    </>
  );
}

function SideBySideTabs(data) {
  const {
    items,
    backgroundColor,
    body,
    className,
    ctaButton,
    ctaLinks,
    modal,
    header,
    divider,
    fullWidth,
    title,
    textAlign,
    videoEmbed,
    videoType,
    videoId,
    vttFile,
    poster,
    paddingTop,
    paddingBottom,
    logo,
    autoPlay,
    muted,
    loop,
    controls,
    reverseOrder
  } = data;

  return (
    <SideBySideTabsSection
      backgroundColor={backgroundColor}
      body={body}
      ctaButton={ctaButton || null}
      ctaLinks={ctaLinks || null}
      className={className}
      modal={modal}
      divider={divider}
      header={header}
      fullWidth={fullWidth}
      items={items}
      title={title}
      textAlign={textAlign}
      videoEmbed={videoEmbed}
      videoType={videoType}
      videoId={videoId}
      vttFile={vttFile}
      poster={poster}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      logo={logo}
      autoPlay={autoPlay}
      muted={muted}
      loop={loop}
      controls={controls}
      reverseOrder={reverseOrder || false}
    />
  );
}

SideBySideTabsSection.propTypes = {
  backgroundColor: PropTypes.string,
  paddingTop: PropTypes.string,
  className: PropTypes.string,
  paddingBottom: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  fullWidth: PropTypes.bool,
  header: PropTypes.shape({
    title: PropTypes.string,
    className: PropTypes.string,
    body: PropTypes.arrayOf(PropTypes.string),
    textAlign: PropTypes.string
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired
    })
  ).isRequired,
  modal: PropTypes.shape({
    type: PropTypes.string,
    src: PropTypes.string
  }),
  divider: PropTypes.bool,
  title: PropTypes.string,
  logo: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  reverseOrder: PropTypes.bool
};

SideBySideTabsSection.defaultProps = {
  backgroundColor: null,
  paddingTop: null,
  paddingBottom: null,
  body: null,
  header: null,
  modal: null,
  divider: false,
  title: null,
  logo: null,
  fullWidth: false,
  className: '',
  reverseOrder: false
};

export default SideBySideTabs;
