/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
// eslint-disable-next-line react/jsx-key
import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { SectionStyles, BaseLinkStyles } from 'aether-marketing';
// eslint-disable-next-line import/no-extraneous-dependencies
const pmUuid = require('@postman/uuid');

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
`;

const TableHeader = styled.th`
  border-bottom: 0 !important;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-top: 0;
  box-sizing: border-box;
  padding: 4px 12px !important;
  width: ${(props) => 100 / props.columnsCount}%; /* Add this line */
`;

const TableRow = styled.tr`
  &:first-child {
    vertical-align: top !important;
  }
`;

const TableData = styled.td`
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-top: 0 !important;
  padding: 4px 12px !important;
  position: relative;
`;

const TableFooter = styled.td`
  background-color: #f8f9fa;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-top: none !important;
  padding: 0px 12px !important;
  p {
    padding: 12px 0;
  }
`;

const TopLine = styled.div`
  border-bottom: 1px solid #e6e6e6;
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 85%;
`;

const BottomLine = styled.div`
  border-bottom: 1px solid #e6e6e6;
  bottom: 0;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 99%;
`;

const Div = styled.div`
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }
  a li:hover {
    border-bottom: 1px solid #0265d2;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }
  li::marker {
    color: #0265d2;
  }
`;

const HoveredColumn = styled.div`
  div[data-first-row].hovered,
  div[data-last-row].hovered {
    border-bottom: 1px solid #784fa9;
  }
  td[data-last-row].hovered {
    background: #c0a8e1 !important;
  }
  td.hovered {
    background: linear-gradient(178.82deg, #fcfaff 10%, #e4d8f6 84.03%);
    border-left: 1px solid #c0a8e1;
    border-right: 1px solid #c0a8e1;
  }
  th.hovered {
    background: #fcfaff;
    border: 1px solid #c0a8e1;
    box-sizing: border-box;
  }
`;

function TableHeaders({ columns }) {
  return (
    <thead>
      <tr>
        {columns.map((column, index) => (
          <TableHeader
            className="text-center align-top position-relative"
            key={pmUuid()}
            data-column-index={index}
            columnsCount={columns.length} /* Add this line */
          >
            {column.icon && (
              <img
                className="mt-2"
                src={column.icon.src}
                alt={column.icon.alt}
                width="40"
              />
            )}
            <p className="mt-2 mb-2 font-weight-bold">{column.header}</p>
            <TopLine data-first-row className="m-0" data-column-index={index} />
          </TableHeader>
        ))}
      </tr>
    </thead>
  );
}

function TableBody({ columns }) {
  const numRows = Math.max(...columns.map((column) => column.rows.length));

  return (
    <tbody>
      <TableRow>
        {columns.map((column, colIndex) => (
          <TableData
            className="border-top-0"
            key={column.key}
            data-column-index={colIndex}
          >
            {Array.from({ length: numRows }).map(
              (_, rowIndex) =>
                column.rows[rowIndex] && (
                  <Div
                    className="my-4"
                    key={pmUuid()}
                    dangerouslySetInnerHTML={{
                      __html: column.rows[rowIndex].text.join('')
                    }}
                  />
                )
            )}
          </TableData>
        ))}
      </TableRow>
      {columns.some((column) => column.footer) && (
        <tr>
          {columns.map((column, colIndex) => (
            <TableFooter
              data-last-row
              key={column.key}
              data-column-index={colIndex}
            >
              <BottomLine data-last-row data-column-index={colIndex} />
              <p className="font-weight-bold">{column.footer || ''}</p>
            </TableFooter>
          ))}
        </tr>
      )}
    </tbody>
  );
}

function TableColumns({ columns }) {
  const handleMouseEnter = useCallback((event) => {
    const columnIndex = event.target.getAttribute('data-column-index');
    document
      .querySelectorAll(`[data-column-index="${columnIndex}"]`)
      .forEach((cell) => {
        cell.classList.add('hovered');
      });
  }, []);

  const handleMouseLeave = useCallback((event) => {
    const columnIndex = event.target.getAttribute('data-column-index');
    document
      .querySelectorAll(`[data-column-index="${columnIndex}"]`)
      .forEach((cell) => {
        cell.classList.remove('hovered');
      });
  }, []);

  useEffect(() => {
    const cells = document.querySelectorAll(
      'th, td, div[data-first-row], div[data-last-row], td[data-last-row]'
    );

    cells.forEach((cell) => {
      cell.addEventListener('mouseenter', handleMouseEnter);
      cell.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      cells.forEach((cell) => {
        cell.removeEventListener('mouseenter', handleMouseEnter);
        cell.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [handleMouseEnter, handleMouseLeave]);

  return (
    <SectionStyles paddingBottom="60px" paddingTop="60px">
      <HoveredColumn>
        <div className="container">
          <div className="row">
            <Table className="table table-responsive">
              <TableHeaders columns={columns} />
              <TableBody columns={columns} />
            </Table>
          </div>
        </div>
      </HoveredColumn>
    </SectionStyles>
  );
}

export default TableColumns;
