import React from 'react';
import PropTypes from 'prop-types';
/* eslint-enable */

import styled from 'styled-components';
import {
  BaseLinkStyles,
  BaseLink,
  ParagraphStyles,
  Paragraph,
  BaseButton
} from 'aether-marketing';
import CardWrapper from './CardWrapper';

import AspectRatioImagePlugin from '../v5_images/AspectRatioImagePlugin';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

// Styled components for card below

const CardBody = styled.div`
  color: ${(props) => props.theme.colors.grey_90};
  padding: ${(props) => (!props.fullWidthImage ? '20px' : '0px')};
`;

const Banner = styled.div`
  background-color: ${(props) => props.theme.colors.grey_00};
  border-radius: ${(props) => props.theme.borderRadius.small};
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%);
  font-size: 1.2rem;
  line-height: 1.2;
  position: absolute;
  text-align: center;
  top: 8px;
  right: 8px;
  text-transform: uppercase;
  padding: 0.8rem;

  .header {
    font-size: 2.4rem;
    font-family: ${(props) => props.theme.fonts.Inter};
    font-weight: 600;
    margin-bottom: 4px;
    line-height: 1;
    color: ${(props) => props.theme.colors.orange_50};
  }
  .subheader {
    font-size: 1.2rem;
    margin-bottom: 0px;
    letter-spacing: 0.05em;
    line-height: 1;
    color: ${(props) => props.theme.colors.orange_50};
  }

  @media (max-width: 576px) {
    right: 8px;
  }
`;

const CardBodyParagraph = styled(ParagraphStyles)`
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }
`;

const Tag = styled.span`
  border: 1px solid ${(props) => props.theme.colors.grey_30};
  border-radius: ${(props) => props.theme.borderRadius.small};
  color: ${(props) => props.theme.colors.grey_50};
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0.5rem 0.8rem;
  text-transform: uppercase;
  white-space: nowrap;
`;

// HighlightTag for special tags, for example "in-person"
const HighlightTag = styled(Tag)`
  border: 1px solid ${(props) => props.theme.colors.orange_50};
  color: ${(props) => props.theme.colors.orange_50};
`;

// Component recipe for card
// Supports optional image, title, body & link
function CardBasic({
  col, // bootstrap classes for col width
  textPadding,
  headerTextAlign,
  textAlign,
  tags,
  header, // title object
  subtitle, // subtitle
  body, // main copy
  link, // Link object
  eyebrow,
  banner,
  media, // image object
  hover, // boolean for hover affect
  clickableCardLink, // boolean, makes entire card clickable
  className,
  fullWidthImage, // boolean, enables padding for entire card
  altBoxShadow,
  roundedCorners // If rounded corners is false, no border radius on CardWrapper
}) {
  return (
    <div
      className={`${col || 'col-lg-6 col-xl-4'} ${className}`}
      key={pmUuid()}
    >
      <CardWrapper
        altBoxShadow={altBoxShadow}
        hover={hover}
        href={clickableCardLink ? link.src : null}
        target={clickableCardLink ? link.target : null}
        gaCategory={clickableCardLink ? link.gaCategory : null}
        gaLabel={clickableCardLink ? link.gaLabel : null}
        className={`h-100 d-flex flex-column ${!roundedCorners ? '' : ''}`}
        fullWidthImage={fullWidthImage}
      >
        {/* Media Container, rendered as AspectRatioImpagePlugin */}
        {media && media.src && (
          <div style={{ position: 'relative' }}>
            {banner && banner.header && banner.subheader && (
              <Banner>
                <p className="header">{banner.header}</p>
                <p className="subheader">{banner.subheader}</p>
              </Banner>
            )}
            <AspectRatioImagePlugin
              src={media.src}
              role="img"
              ratio={media.ratio ? media.ratio : '1/1'}
              aria-label={media.alt || ''}
              height={media.height ? media.height : ''}
              marginBottom={media.marginBottom ? media.marginBottom : 0}
              borderRadius={media.borderRadius}
            />
          </div>
        )}
        {/* Body Container,, can accept any kind of content (p, headers or ol/li) */}
        <CardBody
          style={{ padding: textPadding && textPadding }}
          className={`${textAlign === 'center' ? 'text-center' : null}`}
        >
          {/* Tags */}
          {tags && (
            <p className="mb-3">
              {tags.map((tag) => {
                // if (tag.highlightBorder) {
                //   return <HighlightTag key={pmUuid()}>{tag.text}</HighlightTag>;
                // }
                return <HighlightTag key={pmUuid()}>{tag.text}</HighlightTag>;
              })}
            </p>
          )}
          {eyebrow && eyebrow.text && (
            <p className="mb-2 small">
              <strong>{eyebrow.text}</strong>
            </p>
          )}
          {header && header.text && (
            <h4 className={`mb-3 ${headerTextAlign || ''}`}>{header.text}</h4>
          )}
          {subtitle && subtitle.text && (
            <Paragraph className="mb-3 subtitle">{subtitle.text}</Paragraph>
          )}
          {/* Dangerously Set to make sure we render any unicode symbols like m dash.  Do not include opening and closing tag in body.text string */}
          {body && body.text && (
            <CardBodyParagraph
              className={body.className ? body.className : 'mb-0'}
              alternative={false}
              dangerouslySetInnerHTML={{ __html: body.text }}
              style={body.style && body.style}
            />
          )}
        </CardBody>
        {link.text && link.type !== 'primary' && (
          <div
            style={{
              padding: `${
                textAlign === 'center' ? '0 0 20px' : '0 0 20px 20px'
              }`
            }}
            className={`${
              textAlign === 'center' ? 'text-center' : null
            } mt-auto`}
          >
            {/* If Card Container is set to be a clickable link.  Render the below link AS a P tag, so as not to nest A tags. */}
            <BaseLink
              as={clickableCardLink ? 'span' : 'a'}
              src={link.src}
              target={link.target}
              linkType="arrowLink"
              id={link.id}
              gaLabel={link.gaLabel}
              gaCategory={link.gaCategory}
              className="mr-auto"
            >
              {link.text}
            </BaseLink>
          </div>
        )}
        {link.text && link.type === 'primary' && (
          <BaseButton
            className="ml-auto"
            buttonType="primary"
            as="a"
            src={link.src}
            target={link.target}
            id={link.id ? link.id : null}
            gaLabel={link.gaLabel ? link.gaLabel : null}
            gaCategory={link.gaCategory ? link.gaCategory : null}
          >
            {link.text}
          </BaseButton>
        )}
      </CardWrapper>
    </div>
  );
}

CardBasic.propTypes = {
  col: PropTypes.string,
  link: PropTypes.shape({
    src: PropTypes.string,
    target: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    gaLabel: PropTypes.string,
    gaCategory: PropTypes.string,
    padding: PropTypes.string
  }),
  header: PropTypes.shape({
    text: PropTypes.string,
    tag: PropTypes.string,
    className: PropTypes.string,
    marginBottom: PropTypes.number
  }),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string
    })
  ),
  subtitle: PropTypes.shape({
    text: PropTypes.string
  }),
  body: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.shape({})
  }),
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    ratio: PropTypes.string,
    height: PropTypes.string,
    marginBottom: PropTypes.number,
    borderRadius: PropTypes.number
  }),
  hover: PropTypes.bool,
  clickableCardLink: PropTypes.bool,
  className: PropTypes.string,
  fullWidthImage: PropTypes.bool,
  roundedCorners: PropTypes.bool || PropTypes.string,
  altBoxShadow: PropTypes.bool,
  textPadding: PropTypes.string,
  headerTextAlign: PropTypes.string,
  textAlign: PropTypes.string,
  eyebrow: PropTypes.shape({
    text: PropTypes.string
  }),
  banner: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
};

CardBasic.defaultProps = {
  link: {
    text: null,
    src: null,
    target: null,
    type: null
  },
  header: {
    text: null,
    tag: null,
    className: null,
    marginBottom: null
  },
  tags: null,
  subtitle: {
    text: null
  },
  body: {
    text: null,
    className: null,
    style: null
  },
  media: {
    src: null,
    alt: null,
    ratio: null,
    height: '150px',
    marginBottom: null,
    borderRadius: 0
  },
  textPadding: null,
  headerTextAlign: null,
  textAlign: null,
  eyebrow: {
    text: null
  },
  banner: {
    header: null,
    subheader: null
  },
  col: null,
  hover: false,
  clickableCardLink: false,
  className: '',
  fullWidthImage: false,
  roundedCorners: false,
  altBoxShadow: false
};

export default CardBasic;
