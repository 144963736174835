import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';
import {
  Divider,
  BaseLinkStyles,
  BaseLink,
  UnorderedListStyles,
  OrderedListStyles,
  BaseButton,
  VideoComponent
} from 'aether-marketing';
import FormV7 from './forms/layout/FormV7';
import UnsubscribeForm from './forms/special/UnsubscribeForm';
import CompactForm from './forms/layout/CompactForm';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const SectionStyles = styled.section`
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: ${(props) => props.paddingBottom};
  background: ${(props) => props.backgroundColor};

  .primary-border {
    border-radius: 5px;
    border: 2px solid #636363;
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
  }
  .secondary-border {
    border-top: 2px solid #E6E6E6;
    border-bottom: 2px solid #E6E6E6;
    -webkit-box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    box-shadow: ${(props) => props.theme.shadows.screenshot_shadow};
    max-width: 1600px;
    margin: auto;
    display: block;
    @media screen and (min-width: 1600px) {
      border: 2px solid #E6E6E6;
      border-radius: 5px;
    }
  }
`;

const ContentContainer = styled.div`
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }

  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
`;

function sideBySideFormHero({
  divider,
  fullWidth,
  paddingTop,
  paddingBottom,
  backgroundColor,
  className,
  title,
  logo,
  body,
  ctaLinks,
  ctaButton,
  modal,
  media,
  video,
  videoTwo,
  videoThree,
  videoFour,
  form,
  isUnsubscribeForm
}) {
  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <>
      <SectionStyles
        className={`mb-0 ${className}`}
        backgroundColor={backgroundColor}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 pt-3">
              <ContentContainer>
                {logo ? (
                  <img
                    className="mb-4"
                    height={logo.height}
                    src={logo.src}
                    alt={logo.alt}
                  />
                ) : null}
                {title && <h1>{title}</h1>}
                {body && (
                  <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
                )}
              </ContentContainer>
              {ctaLinks &&
                Array.isArray(ctaLinks) &&
                ctaLinks.map((cta) => (
                  <p key={pmUuid()}>
                    <BaseLink
                      linkType={cta.type}
                      src={cta.src}
                      className={cta.className && cta.className}
                      alternative={cta.alternative ? cta.alternative : false}
                      target={cta.target ? cta.target : 'same-tab'}
                      gaCategory={cta.gaCategory && cta.gaCategory}
                      gaLabel={cta.gaLabel && cta.gaLabel}
                      id={cta.id && cta.id}
                      ariaLabel={cta.ariaLabel && cta.ariaLabel}
                    >
                      {cta.text}
                    </BaseLink>
                  </p>
                ))}
              {ctaButton &&
                Array.isArray(ctaButton) &&
                ctaButton.map((cta) => (
                  <div className="mb-4 mr-3 d-inline" key={pmUuid()}>
                    <BaseButton
                      buttonType={cta.buttonType}
                      as="a"
                      src={cta.src}
                      target={cta.target}
                      id={cta.id}
                      gaCategory={cta.gaCategory}
                      gaLabel={cta.gaLabel}
                      className={cta.type === 'inline' ? 'pl-0' : null}
                      modal={modal || null}
                      icon={cta.icon}
                      dataTest={cta.id}
                    >
                      {cta.text}
                    </BaseButton>
                  </div>
                ))}
              {/* VIDEO ONE (LARGE VIDEO) */}
              {video
                ? video && (
                    <VideoComponent
                      src={video.src}
                      controls={video.controls}
                      autoPlay={video.autoPlay}
                      hostType={video.hostType}
                      caption={video.caption}
                      loop={video.loop}
                      muted={video.muted}
                      container={video.container}
                      title={video.title}
                      border={video.border}
                      aspectRatio={video.aspectRatio}
                      mediaLayout={video.mediaLayout}
                    />
                  )
                : media && <img src={media.src} alt={media.alt} />}
              <div className="row">
                {/* VIDEO TWO */}
                {videoTwo && (
                  <div className="col-xl-4 col-lg-6 col-md-6">
                    <VideoComponent
                      src={videoTwo.src}
                      controls={videoTwo.controls}
                      autoPlay={videoTwo.autoPlay}
                      hostType={videoTwo.hostType}
                      caption={videoTwo.caption}
                      loop={videoTwo.loop}
                      muted={videoTwo.muted}
                      container={videoTwo.container}
                      title={videoTwo.title}
                      border={videoTwo.border}
                      aspectRatio={video.aspectRatio}
                      mediaLayout={videoTwo.mediaLayout}
                    />
                  </div>
                )}
                {/* VIDEO THREE */}
                {videoThree && (
                  <div className="col-xl-4 col-lg-6 col-md-6">
                    <VideoComponent
                      src={videoThree.src}
                      controls={videoThree.controls}
                      autoPlay={videoThree.autoPlay}
                      hostType={videoThree.hostType}
                      caption={videoThree.caption}
                      loop={videoThree.loop}
                      muted={videoThree.muted}
                      container={videoThree.container}
                      title={videoThree.title}
                      border={videoThree.border}
                      aspectRatio={video.aspectRatio}
                      mediaLayout={videoThree.mediaLayout}
                    />
                  </div>
                )}
                {/* VIDEO FOUR */}
                {videoFour && (
                  <div className="col-xl-4 col-lg-6 col-md-6">
                    <VideoComponent
                      src={videoFour.src}
                      controls={videoFour.controls}
                      autoPlay={videoFour.autoPlay}
                      hostType={videoFour.hostType}
                      caption={videoFour.caption}
                      loop={videoFour.loop}
                      muted={videoFour.muted}
                      container={videoFour.container}
                      title={videoFour.title}
                      border={videoFour.border}
                      aspectRatio={video.aspectRatio}
                      mediaLayout={videoFour.mediaLayout}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-6 align-self-center">
              {form.isCompact === 'true' ? (
                <CompactForm form={form} />
              ) : (
                <FormV7 form={form} />
              )}
              {isUnsubscribeForm && <UnsubscribeForm />}
            </div>
          </div>
        </div>
      </SectionStyles>

      {(divider === true && <Divider fullWidth={fullWidth} />) || null}
    </>
    /* eslint-enable jsx-a11y/media-has-caption */
  );
}

sideBySideFormHero.propTypes = {
  divider: PropTypes.bool,
  fullWidth: PropTypes.bool,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  logo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  body: PropTypes.arrayOf(PropTypes.string),
  ctaLinks: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  ctaButton: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  modal: PropTypes.shape({
    type: PropTypes.string,
    src: PropTypes.string
  }),
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  video: PropTypes.shape({
    src: PropTypes.string,
    controls: PropTypes.bool,
    autoPlay: PropTypes.bool,
    hostType: PropTypes.string,
    caption: PropTypes.string,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    container: PropTypes.string,
    title: PropTypes.string,
    border: PropTypes.string,
    aspectRatio: PropTypes.string,
    recap: PropTypes.bool,
    mediaLayout: PropTypes.string
  }),
  videoTwo: PropTypes.shape({
    src: PropTypes.string,
    controls: PropTypes.bool,
    autoPlay: PropTypes.bool,
    hostType: PropTypes.string,
    caption: PropTypes.string,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    container: PropTypes.string,
    title: PropTypes.string,
    border: PropTypes.string,
    aspectRatio: PropTypes.string,
    recap: PropTypes.bool,
    mediaLayout: PropTypes.string
  }),
  videoThree: PropTypes.shape({
    src: PropTypes.string,
    controls: PropTypes.bool,
    autoPlay: PropTypes.bool,
    hostType: PropTypes.string,
    caption: PropTypes.string,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    container: PropTypes.string,
    title: PropTypes.string,
    border: PropTypes.string,
    aspectRatio: PropTypes.string,
    recap: PropTypes.bool,
    mediaLayout: PropTypes.string
  }),
  videoFour: PropTypes.shape({
    src: PropTypes.string,
    controls: PropTypes.bool,
    autoPlay: PropTypes.bool,
    hostType: PropTypes.string,
    caption: PropTypes.string,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    container: PropTypes.string,
    title: PropTypes.string,
    border: PropTypes.string,
    aspectRatio: PropTypes.string,
    recap: PropTypes.bool,
    mediaLayout: PropTypes.string
  }),
  form: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.arrayOf(PropTypes.string),
    marketoForm: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  isUnsubscribeFormTwo: PropTypes.bool
};

sideBySideFormHero.defaultProps = {
  divider: false,
  fullWidth: false,
  paddingTop: null,
  paddingBottom: null,
  backgroundColor: null,
  className: '',
  title: null,
  logo: null,
  body: null,
  ctaLinks: null,
  ctaButton: null,
  modal: null,
  media: null,
  video: null,
  videoTwo: null,
  videoThree: null,
  videoFour: null,
  form: null,
  isUnsubscribeForm: false
};

export default sideBySideFormHero;
